import React from 'react'
import companyLogo from '../assets/companyLogo.svg'
import microsftSSO from '../assets/microsoftSSO.svg'
import './CSS/LoginScreen.css'

const LoginScreen = () => {
    return (
        <>
            {/* <div className="container vh-100 gap-5 d-flex flex-column justify-content-center align-items-center" >
                <h2>Please log in using Microsoft SSO</h2>
                <button className="btn btn-primary" onClick={() => window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/users/auth/microsoft`}>
                    Login with Microsoft
                </button>
            </div> */}
            <div className="container-fluid LoginScreen">
                <div className="row">
                    <div className="col-12 col-md-6 p-0">
                        <div className="bannerImage"></div>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center divLoginContainer">
                        <div className="loginContainer text-center">
                            <img src={companyLogo} alt="Company Logo" className='companyLogo' />
                            <p className='singIn'>Sign In</p>
                            <img src={microsftSSO} alt="microsoft SSO" className='cursor-pointer microsoftSSO' role="button" onClick={() => window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/users/auth/microsoft`} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginScreen