import React from 'react'
import './CSS/SideBar.css'
import companyLogo from '../assets/companyLogo.svg'
import GetPrompts from './GetPrompts'
import UserProfile from './UserProfile'
import NewChatTab from './NewChatTab'
import History from './History'

const SideBar = ({ userData, accordian }) => {
    return (
        <div className='sideBarDiv d-flex flex-column align-items-center justify-content-between'>
            <div className='d-flex flex-column align-items-center justify-content-between w-100'>
                <img src={companyLogo} alt="Company Logo" width={200} height={30} style={{ marginBottom: "32px" }} />
                <NewChatTab />
                {/* <hr /> */}
                <div className="accordion w-100" id={`promptsAccordian${accordian}`}>
                    <GetPrompts auth={false} accordian={accordian} />
                    <hr />
                    <GetPrompts auth={true} accordian={accordian} />
                </div>
                <hr />
                <History />

            </div>
            <div className='w-100'>
                <hr style={{ background: "#D9D9D9", opacity: '1' }} />
                <UserProfile userData={userData} />

            </div>

        </div>
    )
}

export default SideBar