import React from 'react'
import { setIsDropdownOpen, setMainComponent, setPromptSource, useGetAuthPromptsQuery, useGetPromptsQuery } from "../store/slices/truPromptSlice";
import { useDispatch } from 'react-redux';
import { setPromptId } from '../store/slices/truPromptSlice';
import { useAllUsersQuery } from '../store/slices/authSlice';
import userLogo from '../assets/circle-user-solid.svg';
import fontAnimation from '../assets/Animation.gif'
import savedPrompt from '../assets/savedPrompt.svg'
import mostUsedPromptIcon from '../assets/mostUsedPromptIcon.svg'

const GetPrompts = ({ auth, accordian }) => {

    // Fetch all users
    const { data: users, isLoading: usersLoading } = useAllUsersQuery();


    const dispatch = useDispatch();

    const { data: prompts = [] } = useGetAuthPromptsQuery();
    const { data: allprompts = [] } = useGetPromptsQuery();




    const handlePromptSearch = (e, promptId) => {
        e.preventDefault();
        dispatch(setMainComponent('promptResult'));
        dispatch(setPromptId(promptId));
        dispatch(setPromptSource('singlePrompt')); // Set source to singlePrompt
        dispatch(setIsDropdownOpen(false));
    }

    const getUserProfileImage = (userId) => {
        if (!users) return userLogo; // Return default image if users is undefined
        const userProfile = users.filter?.((user) => userId === user._id)
        return userProfile[0].profileImage === '404' ? userLogo : userProfile[0].profileImage;
    }

    const getUserName = (userId) => {
        if (!users) return 'Unknown User'; // Return default name if users is undefined
        const userProfile = users.filter?.((user) => userId === user._id);
        return userProfile[0].name;
    }


    // Assuming prompts is an array of objects as shown in the image
    const sortedPrompts = [...(auth ? prompts : allprompts)].sort((a, b) => {
        if (auth) {
            // Sort by updatedAt in descending order if auth is true
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        } else {
            // Sort by mostUsedPromptCount in descending order if auth is false
            return b.mostUsedPromptCount - a.mostUsedPromptCount;
        }
    });

    if (usersLoading) {
        return <>
            <img src={fontAnimation} alt="Loading Text" />
        </>
    }

    return (
        <div className="tab w-100">
            <div className="">
                <div className='accordion-header mb-0'>
                    <div className={`accordion-button ${!auth ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={auth ? '#mySavedPrompts' : '#mostUsedPrompts'} aria-expanded="true" aria-controls={auth ? 'mySavedPrompts' : 'mostUsedPrompts'}>
                        {auth && <h5 className="mb-0 SideBarTitle"><img src={savedPrompt} alt='Saved Prompt' style={{ marginRight: "8px" }} />My Saved Prompts</h5>}
                        {!auth && <h5 className="mb-0 SideBarTitle"><img src={mostUsedPromptIcon} alt='Most Used Prompt' style={{ marginRight: "8px" }} />Most Used Prompts</h5>}
                    </div>
                </div>
                <div id={auth ? 'mySavedPrompts' : 'mostUsedPrompts'} className={`accordion-collapse collapse ${!auth ? 'show' : ''}`} data-bs-parent={`#promptsAccordian${accordian}`}>
                    <div className="accordion-body">
                        <ul className='list-group' id={!auth ? "most-used" : "recent-saved"}>
                            {sortedPrompts.length > 0 ? (
                                sortedPrompts.map((prompt, index) => (
                                    <li key={index} className="list-item d-flex align-items-center justify-content-between">
                                        <div className='d-flex align-items-center'>
                                            {/* <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={<Tooltip id={`tooltip-${index}`}>{getUserName(prompt.userId)}</Tooltip>}
                                                >
                                                    <img
                                                        src={getUserProfileImage(prompt.userId)}
                                                        alt='Profile'
                                                        width={32}
                                                        height={32}
                                                        className="rounded-circle"
                                                        style={{ marginRight: '5px' }}

                                                    />
                                                </OverlayTrigger> */}
                                            <button
                                                className="btn btn-link p-0 list-item-left tgpt-list-butn"
                                                onClick={(e) => handlePromptSearch(e, prompt._id)}
                                                title={prompt.userPrompt}
                                                data-bs-dismiss={window.innerWidth < 768 ? "offcanvas" : undefined}
                                            >
                                                {prompt.userPrompt}
                                                {/* <TruncateText text={prompt.userPrompt} wordLimit={7} /> */}
                                            </button>
                                        </div>
                                        {!auth && prompt.mostUsedPromptCount !== 0 && (<span className="badge rounded-pill count-searches">{prompt.mostUsedPromptCount}</span>)}
                                    </li>
                                ))
                            ) : (
                                <li className="list-item d-flex align-items-center justify-content-between emptyList">
                                    Start creating your first prompt!
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>

        </div >
    );


}

export default GetPrompts