import React from 'react'
import NewChat from './NewChat'
import PromptResults from './PromptResults'
import { useSelector } from 'react-redux';
import { selectMainComponent, selectPromptId, selectPromptSource, useGetSinglePromptQuery } from '../store/slices/truPromptSlice';
import HandlePrompt from './HandlePrompt';

const MainComponent = ({ loading, prompt, setPrompt, userData, setLoading, setCurrentPrompt, selectedOptions, openAIResults, setSelectedOptions, setOpenAIResults }) => {

    const Results = useSelector(selectPromptSource);
    const promptId = useSelector(selectPromptId);
    const mainComponent = useSelector(selectMainComponent);
    const { data: singlePrompt, } = useGetSinglePromptQuery(promptId, {
        skip: !promptId,
    });

    return (
        <>
            <div className={`mainComponent d-flex flex-column align-items-center ${(Results === 'singlePrompt' || mainComponent !== 'History') ? 'singlePrompt' : 'AllPrompts'}`}>
                {mainComponent === 'newChat' ? (
                    <NewChat
                        userData={userData}
                        setLoading={setLoading}
                        setCurrentPrompt={setCurrentPrompt}
                        selectedOptions={selectedOptions}
                        openAIResults={openAIResults}
                        setSelectedOptions={setSelectedOptions}
                        setOpenAIResults={setOpenAIResults}
                    // mainComponent={mainComponent}
                    />
                ) : (
                    <>
                        <PromptResults
                            loading={loading}
                            prompts={(Results === 'openAIPrompts') ? openAIResults : singlePrompt || []}
                            setPrompt={setPrompt}
                            promptFromDB={(Results === 'singlePrompt') ? true : false}
                            setLoading={setLoading}
                            setOpenAIResults={setOpenAIResults}
                            setSelectedOptions={setSelectedOptions}
                            selectedOptions={selectedOptions}

                        />
                        {mainComponent !== 'History' && (
                            <HandlePrompt
                                prompt={prompt}
                                setPrompt={setPrompt}
                                setLoading={setLoading}
                                setCurrentPrompt={setCurrentPrompt}
                                selectedOptions={selectedOptions}
                                openAIResults={openAIResults}
                                setSelectedOptions={setSelectedOptions}
                                setOpenAIResults={setOpenAIResults}
                            // isDropdownOpen={isDropdownOpen}
                            // setIsDropdownOpen={setIsDropdownOpen}
                            // dropdownRef={dropdownRef}
                            // mainComponent={mainComponent}
                            />
                        )}

                    </>

                )}
            </div >
        </>
    )
}

export default MainComponent