import React from 'react'
import { useDispatch } from 'react-redux';
import newChatIcon from '../assets/newChatIcon.svg'
import { setMainComponent, setPromptSource } from '../store/slices/truPromptSlice'


const NewChatTab = () => {

    const dispatch = useDispatch();


    const handleNewChatClick = (e) => {
        e.preventDefault();
        dispatch(setMainComponent('newChat'));
        dispatch(setPromptSource('singlePrompt'));
    }

    return (
        <div
            className='newChatDiv d-flex justify-content-between w-100'
            role='button'
            data-bs-dismiss={window.innerWidth < 768 ? "offcanvas" : ''}
            onClick={(e) => handleNewChatClick(e)}>
            <p className='newChatText'>New Chat</p>
            <img src={newChatIcon} alt="New Chat" width={24} height={24} />
        </div>
    )
}

export default NewChatTab