// src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import apiSlice from './slices/apiSlice';
import { truPromptApi, promptReducer, promptResultsReducer } from './slices/truPromptSlice';
import { authApi } from './slices/authSlice';

const store = configureStore({
    reducer: {
        api: apiSlice,
        prompt: promptReducer,
        promptResults: promptResultsReducer,
        [truPromptApi.reducerPath]: truPromptApi.reducer, //Add RTK Query reducer
        [authApi.reducerPath]: authApi.reducer, //Add Microsoft SSO login
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(truPromptApi.middleware, authApi.middleware), // Add RTK Query middleware
});

export default store;
