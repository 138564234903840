import React, { useRef } from 'react';
import AISubmit from '../assets/AISubmit.svg'
import Arrow from '../assets/accordionIcon.svg'

import { useDispatch, useSelector } from 'react-redux';
import { handlePromptSearch } from './openAIUtils';
import Swal from 'sweetalert2';
import '@sweetalert2/theme-dark';
import { selectIsDropdownOpen, setIsDropdownOpen, setMainComponent, setPromptSource } from '../store/slices/truPromptSlice';


const HandlePrompt = ({
    prompt,
    setPrompt,
    setLoading,
    selectedOptions,
    setSelectedOptions,
    openAIResults,
    setOpenAIResults,
    // dropdownRef,
}) => {


    const dispatch = useDispatch();

    const isDropdownOpen = useSelector(selectIsDropdownOpen)

    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    // const [prompt, setPrompt] = useState("");

    // useEffect(() => {
    //     if (isDropdownOpen) {
    //         setIsBlur(!isBlur)
    //     }
    // }, [isDropdownOpen, isBlur, setIsBlur])


    const optionsList = [
        { name: "SEO", param: "As an SEO expert, focus on tasks like on-page optimization (meta tags, content structure), off-page strategies (backlink building, outreach), and performing a comprehensive site audit to identify issues and opportunities" },
        { name: "Design", param: "As a designer, consider aspects such as user experience, visual aesthetics, brand consistency, and effective communication through design elements" },
        { name: "Communication", param: "As a communication specialist, focus on effective messaging, audience engagement, and strategies for clear and impactful communication across various channels" },
        { name: "Web Development", param: "As a web developer, consider aspects such as responsive design, performance optimization, accessibility, and modern web technologies" },
        { name: "Lead Generation", param: "As a lead generation expert, focus on strategies to attract and convert potential customers into qualified leads for the business" },
        { name: "Lifecycle Marketing", param: "As a lifecycle marketing specialist, focus on strategies for customer acquisition, retention, and growth across various stages of the customer journey" },
        { name: "Business Development", param: "As a business development professional, focus on strategies for growth, partnerships, market expansion, and improving overall business performance" },
    ];

    const toggleOption = (optionName) => {
        setSelectedOptions(prev =>
            prev.includes(optionName)
                ? prev.filter(name => name !== optionName)
                : [...prev, optionName]
        );
    };


    // const handleDropdownToggle = () => {
    //     // setIsDropdownOpen(prev => !prev);
    //     dispatch(setIsDropdownOpen(prev => !prev));
    // };

    // const handleCheckboxClick = (optionName) => {
    //     toggleOption(optionName); // Toggle the checkbox state
    //     // Prevent dropdown from closing
    // };


    // document.addEventListener('mousedown', handleClickOutside);
    // document.removeEventListener('mousedown', handleClickOutside);


    const getDropdownText = () => {
        if (selectedOptions.length === 0) return "Select Functions";
        if (selectedOptions.length === 1) return selectedOptions[0];
        return (
            <>
                <span>{selectedOptions[0]}</span><span className='functionsCount'>+{selectedOptions.length - 1}more</span>
            </>
        );
    };


    const onSubmit = async (e) => {
        e.preventDefault();
        // dispatch(setPromptId(null));
        // Set source to singlePrompt
        if (selectedOptions.length === 0) {
            Swal.fire({
                title: 'Oops...!',
                text: 'Please select one of the option from the Dropdown.',
                icon: 'warning',
                confirmButtonText: 'OK',
                theme: 'dark'
            });
            return
        }
        const wordCount = prompt.trim().split(/\s+/).length; // Split by whitespace and count words
        if (wordCount < 3) {
            Swal.fire({
                title: 'Oops...!',
                text: 'Ensure your search query includes a minimum of three words.',
                icon: 'warning',
                confirmButtonText: 'OK',
                theme: 'dark'
            });
            return
        }
        try {
            handlePromptSearch(e, prompt, setPrompt, setLoading, setOpenAIResults, selectedOptions, setSelectedOptions, dispatch)
            dispatch(setMainComponent('promptResult'));
            dispatch(setPromptSource('openAIPrompts'));
        } catch (error) {
            Swal.fire({
                title: 'Oops...!',
                text: 'Error while getting the OpenAIResults...',
                icon: 'error',
                confirmButtonText: 'OK',
                theme: 'dark'
            });
        }
    }



    return (
        <form onSubmit={onSubmit} className="d-flex flex-sm-row flex-column userInputDiv">
            <div className="dropdown selectFunctionsDiv d-flex justify-content-between align-items-center"
                ref={dropdownRef}

            >
                <button
                    className="selectFunctionsBtn d-flex justify-content-between align-items-center"
                    type="button"
                    aria-expanded={isDropdownOpen}
                    onClick={() => {
                        dispatch(setIsDropdownOpen(!isDropdownOpen));
                    }}
                >
                    <span className='selectFunctionsTitle d-flex align-items-center'>{getDropdownText()}</span>
                    <img
                        src={Arrow}
                        width={18}
                        height={18}
                        alt='Arrow'
                        className='selectFunctionsArrow' />
                </button>
                {isDropdownOpen && (
                    <ul className="dropdown-menu show">
                        {optionsList.map((option) => (
                            <li key={option.name} onChange={() => {
                                toggleOption(option.name);
                                // handleCheckboxClick(option.name)
                            }}>
                                <div className="dropdown-item">
                                    <div className="form-check" >
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`check-${option.name}`}
                                            checked={selectedOptions.includes(option.name)}
                                            role="button"
                                        />
                                        <label className="form-check-label" htmlFor={`check-${option.name}`} role="button">
                                            {option.name}
                                        </label>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="inputPrompt d-flex">

                <input
                    className="userInputField"
                    value={`${prompt}`}
                    placeholder="Message AI Prompt Generator"
                    onChange={(e) => {
                        setPrompt(e.target.value)
                    }}
                    onClick={() => {
                        // setIsDropdownOpen(false)
                        dispatch(setIsDropdownOpen(false));
                        // setIsBlur(false)
                    }}

                    style={{ flex: 1 }}
                />
                <button className="AISubmit" type="submit" onClick={() => {
                    // setIsBlur(false)
                    dispatch(setIsDropdownOpen(false));
                    // setIsDropdownOpen(false)
                }}>
                    <img
                        src={AISubmit}
                        width={24}
                        height={24}
                        alt='AI'
                        className=''
                    />
                </button>
            </div>
        </form>
    );
};

export default HandlePrompt;